import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {navigate, Link} from 'gatsby';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {serviceCategoryArr} from '../../Category';

function ServiceList(props) {
  const [actions] = useOutlet('actions');
  const params = qs.parse(props.location.search);

  const [articles, setArticles] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(
    params.cat
      ? serviceCategoryArr.find((item) => item.name === params.cat)
      : serviceCategoryArr[0],
  );
  const [dimension] = useOutlet('dimension');
  const [banner, setBanner] = React.useState('');

  const [bannerDimension, setBannerDimension] = React.useState(null);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem?.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments(
          'Article_Default',
          {},
          [],
          {
            offset: 0,
            limit: 350,
          },
          null,
        );
        let dataList = resp.results;
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }
    async function fetchBanner() {
      actions.setLoading(true);
      let bannerList = await actions.fetchDocuments('banner');
      bannerList = bannerList.results;
      let _banner = await bannerList.filter(
        (banner) => banner.type === 'service',
      )[0];
      actions.setLoading(false);
      setBanner(_banner.image);
    }
    fetchBanner();

    fetchArticleData();
  }, [actions]);

  React.useEffect(() => {
    if (params.cat) {
      if (params.cat !== selectedTab.name) {
        const nextTab = serviceCategoryArr.find(
          (item) => item.name === params.cat,
        );
        setSelectedTab(nextTab);
      }
    }
  }, [params.cat, selectedTab.name]);

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="service-banner"
          src={banner}
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">SERVICE</div>
        <div className="title" style={{marginBottom: 30}}>
          服務項目
        </div>

        <div className="tab-wrapper" style={{marginTop: 30}}>
          {serviceCategoryArr.map((c, idx) => (
            <div
              className={`${selectedTab.name === c.name && 'selected-tab'} tab`}
              onClick={() => {
                setSelectedTab(c);
                navigate(c.path);
              }}
              key={idx}>
              {c.name}
            </div>
          ))}
        </div>
        <div style={{marginTop: 40, marginBottom: 40}}>
          <img
            alt="service-img"
            src={
              dimension.rwd === 'desktop'
                ? selectedTab.img
                : selectedTab.img_small
            }
            style={{
              width: '100%',
              // height: 300,
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
          {/* <div className="content" style={{marginTop: 30}}>
            {selectedTab.description}
          </div> */}
        </div>
        <section className="grid" style={{flexDirection: 'column'}}>
          {articles
            .filter(
              (a) => (a?.label && a.label.indexOf('服務項目') >= 0) || false,
            )
            .filter(
              (a) =>
                (a?.label && a.label.indexOf(selectedTab.name) >= 0) || false,
            )
            .map((item, idx) => (
              <div
                onClick={() => {
                  navigate(`/service?id=${item.id}`);
                }}
                className="grid-item-large"
                key={idx}
                style={{marginBottom: dimension.rwd === 'desktop' ? 50 : 0}}>
                <div
                  className="grid-item-img-large"
                  style={{
                    boxShadow: '0px 0px 7px 1px rgba(132, 132, 132, 0.2)',
                    backgroundImage: `url(${item.image})`,
                  }}
                />
                <div className="grid-context-container">
                  <p
                    style={{letterSpacing: 3.5}}
                    className="grid-item-title primary-color">
                    {item.title}
                  </p>
                  {/* <p className="grid-item-date" style={{marginTop: 10}}>
                    {new Date(item.created).toLocaleString()}
                  </p> */}
                  <p className="grid-item-outline" style={{marginTop: 10}}>
                    {item.outline}
                  </p>
                </div>
              </div>
            ))}
        </section>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .outline {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export default ServiceList;
